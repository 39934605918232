import { Container, Stack, useMediaQuery, useTheme } from '@mui/material';
import { Header } from './Header';
import { SmallScreenMessage } from './SmallScreenMessage';
import { StatusBar } from './StatusBar';
import { Storage } from './storage';
import { SplashScreen } from './SplashScreen'
import { useEffect } from 'react';
import { useState } from 'react';
import { useAppState } from '@src/overmind';
import { useActions } from '@src/overmind';

export const Main = () => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const {showSplash} = useAppState().ui
  const {setSplash} = useActions().ui
  const [time, setTime] = useState(showSplash)

  useEffect(() => {
    if (showSplash) {
      const interval = setInterval(() => {
        setTime(false);
        setSplash(false)
      }, 1500);

      return () => clearInterval(interval);
    }
  }, []);

  return (
    <Container>
      <Stack gap={isMobile ? 0 : 3}>
        <Header />
        {isMobile ? <SmallScreenMessage /> : time ? <SplashScreen /> : <Storage />}
        <StatusBar />
      </Stack>
    </Container>
  );
};
