import { SvgIcon } from '@mui/material';
import React from 'react';
import './css/tea.css';

interface IconProps {
  color?: string;
  size?: number;
}

// Not a tea icon anymore, since this is the Love Coding Branding, Heart Emoji Icon from OpenMoji, authored by Laura Humpfer, licensed under CC-BY SA 4.0
// (https://creativecommons.org/licenses/by-sa/4.0/)
export const TeaIcon = ({ color = '#33406f', size = 1 }: IconProps) => {
  return (
    <SvgIcon
      className="tea"
      width={24 * 3.5}
      height={24 * 3.5}
      sx={{ transform: `scale(${3.5})` }}
    >
      <svg id="svg-class" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
  <g id="heart">
    <path fill="#EA5A47" d="M59.5,25c0-6.9036-5.5964-12.5-12.5-12.5c-4.7533,0-8.8861,2.6536-11,6.5598 C33.8861,15.1536,29.7533,12.5,25,12.5c-6.9036,0-12.5,5.5964-12.5,12.5c0,2.9699,1.0403,5.6942,2.7703,7.8387l-0.0043,0.0034 L36,58.5397l20.7339-25.6975l-0.0043-0.0034C58.4597,30.6942,59.5,27.9699,59.5,25z"/>
  </g>
</svg>
    </SvgIcon>
  );
};
