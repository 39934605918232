import { Paper, Stack, useTheme } from '@mui/material';
import { db } from '@src/db';
import { useAppState } from '@src/overmind';
import type { ViewProps, ViewType } from '@src/types';
import { DocumentViews } from '@src/views';
import { useLiveQuery } from 'dexie-react-hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TeaIcon } from '@src/icons';

export const SplashScreen = () => {
  const { userState } = useAppState().auth;

  const { t } = useTranslation('LWC');
  const { palette } = useTheme();

  return (
    <Stack direction="row" justifyContent="center">
        <TeaIcon color={palette.secondary.dark} size={2} />
    </Stack>
  );
};
