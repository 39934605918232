import { derived } from 'overmind';
import { Context } from '../';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type State = {
  loaded: boolean;
  rootName?: string;
  schemaId: string;
  schemaName: string;
  touched: boolean;
  url?: string;
  xml?: string;
  FromGitHub: boolean;
  GitHubToken: string;
  GitHubRepo: string;
  GitHubOwner: string;
  isPrivate: boolean;
  isReload: boolean;
};

export const state: State = {
  isReload: false,
  loaded: false,
  schemaId: '',
  schemaName: derived((state: State, rootState: Context['state']) => {
    const schema = rootState.editor.schemasList.find((sch) => sch.id === state.schemaId);
    if (!schema) return '';
    return schema.name;
  }),
  touched: false,
  GitHubToken: "",
  GitHubRepo: "",
  GitHubOwner: "",
  FromGitHub: false,
  isPrivate: false,
};
